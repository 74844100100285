import { render, staticRenderFns } from "./loginSso.vue?vue&type=template&id=8fde2104&scoped=true"
import script from "./loginSso.vue?vue&type=script&lang=js"
export * from "./loginSso.vue?vue&type=script&lang=js"
import style0 from "./loginSso.vue?vue&type=style&index=0&id=8fde2104&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fde2104",
  null
  
)

export default component.exports