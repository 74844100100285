<template>
    <div class="wrapper-loading">
      <img src="assets/logo/logo-polpum.svg" alt="" width="201px" height="87px" class="mb-5" />
      <b-spinner v-if="status == ''" large ></b-spinner>
      <span v-if="status == 'error'">Gagal login...</span>
    </div>
</template>
<style scoped>
.wrapper-loading{
  height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: fixed;
}
</style>
<script>

import axios from "axios";
import Swal from "sweetalert2";
export default {
  data(){
    return {
      resp: '',
      status: ''
    }
  },
  mounted(){
    setTimeout(() => {
      this.loginCallback()  
    }, 3000);
  },
  methods:{
    loginCallback(){

      if(this.$route.query.code){
      // var redirecturi = 'http://localhost:4000'
            var redirecturi = process.env.VUE_APP_APPLICATION_BASE_URL

            var body = 'client_id='+ process.env.VUE_APP_SSO_CLIENTID +
            '&client_secret=' + process.env.VUE_APP_SSO_SECRET_KEY +
            '&grant_type=authorization_code' +
            '&code='+ this.$route.query.code +
            '&redirect_uri='+ redirecturi + '/login-callback'
      axios
          .post(
            process.env.VUE_APP_SSO_BASE_URI + `/auth/realms/SPBE/protocol/openid-connect/token`,
            body,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }
          )
          .then((response) => {
            this.resp = response.data.access_token
            this.loginSystem('SSO_NASIONAL')
          })
          .catch(error => {
            this.status = 'error'
            this.resp = error
            Swal.fire({
              icon: 'warning',
              title: error,
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok"
            });
          });

      } else {
        var objToken = JSON.parse(decodeURIComponent(this.$route.query.at))
        this.resp = objToken.access_token
        this.loginSystem('SSO_POLPUM')
      }
    },

    loginSystem(type){
      var body = {token: this.resp, sso_type: type}
      this.$store
        .dispatch("loginSso", body)
        .then(() => {
          // if(!res.data.isLengkap) this.$router.push('/formdataormas')
          // else this.$router.push({ name: "dashboard" })
          this.$router.push({ name: "dashboard" })
        })
        .catch(error => {
          this.status = 'error'
          this.resp = error
          Swal.fire({
            icon: 'warning',
            title: error.message,
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok"
          });
        });
    }
  }
}
</script>